.side-drawer{
    height: 100%;
   background-color: #eee;
   color: grey;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
    padding: 25px;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer li{
    margin-bottom: 4%;
}

.side-drawer ul{
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
}

.drop-down{
    color: grey
}

.side-drawer a{
    color: grey;
    text-decoration: none;

}

.dropdown-item a{
    color: rgb(119, 54, 4);
}
.side-drawer a:hover,
.side-drawer a:active{
    color: black;
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    

}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}

.i18n{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}