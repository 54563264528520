@import url(https://fonts.googleapis.com/css2?family=Karma:wght@300;400;700&display=swap);
body{
  font-family: 'Karma', serif;
  margin: 0;
  color: rgb(60, 77, 75); 
}

input {
  margin-bottom: 25px;
  outline: none;
  border: none;
  /* border-bottom: 1px dotted lightgrey; */

}

.biog-logos{
  display: inline;
}

.biog-logos img{
  width:150px;
  box-shadow: 2px 2px 2px gray;
  border-radius: 5px;
  margin: 20px;
}



.blue{
  margin-top: -20px;
  margin-left: 10px;
}

/* .contact-options{
  display: flex;
  justify-content: space-evenly;
} */

.contact-options a{
  color: rgb(60, 77, 75); 

}

.contact-options a:hover{
  text-decoration: none;
  color: rgb(107, 185, 203);


}

.contact-photo{
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 58px;
}






.form-control{
  border: none;
  /* border-bottom: 1px solid lightgray; */
  background-color: #eee;
}

textarea{
  border: none;
  outline: none;
}

.form-control:focus{
  outline: none;
  border: none;
}

.icon{
  margin-right: 10px;
}

.nav-link{
  text-align: center;
  margin-top: 27px;
}


.nav-link img{
  width: 50px;
opacity:0;
margin-top: -9px;
}

.nav-link:hover img{
  opacity: 1;
  transition: 700ms all ease;
  }

  .active{
    color:black;
  }

.active img{
opacity: 1;
transition: 700ms all ease;
}

.navbar{
  transition: 500ms;
}

.btn-light{
  background-color: rgb(107, 185, 203);
  color: white;
}

.btn-light:hover{
  color: rgb(107, 185, 203);
  background-color: white;
  border: 1px solid rgb(107, 185, 203)
}

.content{
  padding: 3%;
  min-height: 80vh;
}


  .country-flag:hover{
    cursor: pointer;
  }

  .footer{
    height: 60px;
    padding: 25px;
  }

  .footer a{
    color: rgb(107, 185, 203);
  }

  .home-banner{

    width: 80%;
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .homepage-logo{
    width: 400px;
    margin: 25px auto;

  }

.title-bar{

  margin-bottom: 20px;

}
.post-card{
background-color: whitesmoke;
padding: 20px;
margin-top: 10px;

}

.sharing-buttons{
  text-align: right;
}

.text-box{
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 5px;
  margin-top: 25px;
}

 /*image overlays*/

.main-box{
	width: 100%;
    position: relative;
    float: left;
    margin: 0 4px;
}
.main-box img{
	position:relative;
  	z-index:1;
}


.overlay img{
    width: 70%;
    margin: auto;
}



.overlay{
	position: absolute;
    z-index: 2;
    top: 0;
    opacity: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    background-color: #05050598;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    text-align: center;
}
.main-box:hover .overlay{
	opacity:0.9;
}
  
  .title-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

.photo{
  width: 100%;
  margin: 5px;
}

.photo:hover{
  cursor: pointer;
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto 1px;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.backdrop h5{
  text-align: center;
  color: white;
}


.photo-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0, 0.5);
  padding: 10px;
  width: 60%;
  margin: auto;
}


.toolbar{
    position: fixed;
    background-color: #eee;
    width: 100%;
    min-height: 56px;
    top: 0;
    left: 0;
    z-index: 200;
    
}

.toolbar__navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo{
    margin-left: 1rem;
}
.toolbar__logo a{
color: white;
text-decoration: none;
font-size: 1.5rem;
}

.spacer{
    flex: 1 1;
}
.toolbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 0 .5rem ;
}

.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: rgb(185, 205, 207);
}


.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 2px;
    background: white;
  }

  .toggle-button:hover{
    transform: rotate(90deg);
    transition: 500ms;
  }
.side-drawer{
    height: 100%;
   background-color: #eee;
   color: grey;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
    padding: 25px;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer li{
    margin-bottom: 4%;
}

.side-drawer ul{
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
}

.drop-down{
    color: grey
}

.side-drawer a{
    color: grey;
    text-decoration: none;

}

.dropdown-item a{
    color: rgb(119, 54, 4);
}
.side-drawer a:hover,
.side-drawer a:active{
    color: black;
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    

}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}

.i18n{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    z-index: 100;
    top: 0;
    left: 0;
}
