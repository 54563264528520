@import url('https://fonts.googleapis.com/css2?family=Karma:wght@300;400;700&display=swap');
body{
  font-family: 'Karma', serif;
  margin: 0;
  color: rgb(60, 77, 75); 
}

input {
  margin-bottom: 25px;
  outline: none;
  border: none;
  /* border-bottom: 1px dotted lightgrey; */

}

.biog-logos{
  display: inline;
}

.biog-logos img{
  width:150px;
  box-shadow: 2px 2px 2px gray;
  border-radius: 5px;
  margin: 20px;
}



.blue{
  margin-top: -20px;
  margin-left: 10px;
}

/* .contact-options{
  display: flex;
  justify-content: space-evenly;
} */

.contact-options a{
  color: rgb(60, 77, 75); 

}

.contact-options a:hover{
  text-decoration: none;
  color: rgb(107, 185, 203);


}

.contact-photo{
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 58px;
}






.form-control{
  border: none;
  /* border-bottom: 1px solid lightgray; */
  background-color: #eee;
}

textarea{
  border: none;
  outline: none;
}

.form-control:focus{
  outline: none;
  border: none;
}

.icon{
  margin-right: 10px;
}

.nav-link{
  text-align: center;
  margin-top: 27px;
}


.nav-link img{
  width: 50px;
opacity:0;
margin-top: -9px;
}

.nav-link:hover img{
  opacity: 1;
  transition: 700ms all ease;
  }

  .active{
    color:black;
  }

.active img{
opacity: 1;
transition: 700ms all ease;
}

.navbar{
  transition: 500ms;
}

.btn-light{
  background-color: rgb(107, 185, 203);
  color: white;
}

.btn-light:hover{
  color: rgb(107, 185, 203);
  background-color: white;
  border: 1px solid rgb(107, 185, 203)
}

.content{
  padding: 3%;
  min-height: 80vh;
}


  .country-flag:hover{
    cursor: pointer;
  }

  .footer{
    height: 60px;
    padding: 25px;
  }

  .footer a{
    color: rgb(107, 185, 203);
  }

  .home-banner{

    width: 80%;
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .homepage-logo{
    width: 400px;
    margin: 25px auto;

  }

.title-bar{

  margin-bottom: 20px;

}
.post-card{
background-color: whitesmoke;
padding: 20px;
margin-top: 10px;

}

.sharing-buttons{
  text-align: right;
}

.text-box{
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 5px;
  margin-top: 25px;
}

 /*image overlays*/

.main-box{
	width: 100%;
    position: relative;
    float: left;
    margin: 0 4px;
}
.main-box img{
	position:relative;
  	z-index:1;
}


.overlay img{
    width: 70%;
    margin: auto;
}



.overlay{
	position: absolute;
    z-index: 2;
    top: 0;
    opacity: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    background-color: #05050598;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    text-align: center;
}
.main-box:hover .overlay{
	opacity:0.9;
}
  
  .title-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

.photo{
  width: 100%;
  margin: 5px;
}

.photo:hover{
  cursor: pointer;
}

/* modal styles */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto 1px;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.backdrop h5{
  text-align: center;
  color: white;
}


.photo-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0, 0.5);
  padding: 10px;
  width: 60%;
  margin: auto;
}

